<template>
  <div class="view-orders">
    <b-container v-if="ordersFetched" class="mb-3">
      <b-row class="text-center">
        <h1 class="my-3 px-3 p-sm-0 py-0 py-md-3 page-heading w-100">{{ $t('view_orders_purchased_heading_copy') }}</h1>
      </b-row>
      <div v-if="paginationBuyerTotal === 0" class="mx-auto">
        <h1>{{ $t('orders_empty_heading') }}</h1>
        <i class="mb-3 fa-solid fa-basket-shopping fa-4x"></i>
      </div>
      <div v-else >
        <div class="row justify-content-center mb-4">
          <StatsBox :title="$t('in_progress')" :total="orderStatusTotal[3] || 0" v-on:clicked="switchTab(2)" />
          <StatsBox :title="$t('delivered')" :total="orderStatusTotal[4] || 0" v-on:clicked="switchTab(3)" />
          <StatsBox :title="$t('disputed')" :total="orderStatusTotal[7] || 0" v-on:clicked="switchTab(6)" />
        </div>
        <b-card no-body>
          <b-tabs card v-model="tabIndex">
            <b-tab :title="$t('all')" v-on:click="getOrdersBuyer(1)">
              <PurchasesTable :moreInfo="true" />
            </b-tab>

            <OrderPurchase :title="$t('awaiting_acceptance')" :status="0" :status-title="$t('job_waiting_for_decision')"/>
            <OrderPurchase :title="$t('in_progress')" :status="3" :status-title="$t('in_progress')"/>
            <OrderPurchase :title="$t('delivered')" :status="4" :status-title="$t('delivered')"/>
            <OrderPurchase :title="$t('completed')" :status="5" :status-title="$t('completed')"/>
            <OrderPurchase :title="$t('declined')" :status="2" :status-title="$t('job_declined')"/>
            <OrderPurchase :title="$t('disputed')" :status="7" :status-title="$t('disputed')"/>
            <OrderPurchase :title="$t('cancelled')" :status="8" :status-title="$t('cancelled')"/>
            <OrderPurchase :title="$t('expired')" :status="9" :status-title="$t('expired')"/>

          </b-tabs>
        </b-card>
      </div>
    </b-container>
    <div v-else class="mx-auto my-5">
      <b-spinner large label="Large Spinner"></b-spinner>
      <div>{{ $t('loading_orders') }}</div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../apiService.js'
import OrderPurchase from '../../components/Tabs/OrderPurchase'
import StatsBox from '../../components/MyAccount/StatsBox'
import PurchasesTable from '../../components/MyAccount/PurchasesTable'
import { mapActions } from 'vuex'

export default {
  name: 'ViewMyPurchases',
  components: { OrderPurchase, StatsBox, PurchasesTable },
  data () {
    return {
      username: '',
      ordersFetched: false,
      ordersBuyer: [],
      paginationBuyerTotal: 0,
      orderStatusTotal: {},
      tabIndex: 0
    }
  },
  mounted () {
    this.setNewDeliveriesCount(0)
    this.getOrders()
    ApiService.getTotalOrdersByStatus('buyer').then((resp) => {
      this.orderStatusTotal = resp.data.data
    }).catch({

    })
  },
  methods: {
    ...mapActions(['setNewDeliveriesCount']),
    getOrders () {
      this.username = localStorage.getItem('username')
      this.getOrdersBuyer(1)
    },
    getOrdersBuyer (page) {
      ApiService.getOrders(page, 'buyer').then(response => {
        this.ordersBuyer = response.data.data.results
        this.paginationBuyerTotal = response.data.data.pagination.total
        this.ordersFetched = true
      }).catch(() => {
      })
    },
    switchTab (data) {
      this.tabIndex = data
      this.$nextTick(() => {
        document.querySelector('.nav-tabs .nav-item a.active').click()
      })
    }
  }
}
</script>
