<template>
  <b-tab :title="title" v-on:click="getOrders(1)" lazy>
    <div v-if="!paginationLoad" class="mx-auto my-5">
      <b-spinner large label="Large Spinner"></b-spinner>
      <div>{{ $t('loading_orders') }}</div>
    </div>
    <div v-else>
      <div v-if="paginationTotal > 0">
        <table class="table table-striped table-bordered add-manage-table table demo footable-loaded footable" data-filter="#filter" data-filter-text-only="true">
          <thead>
          <tr>
            <th>{{ $t('view_orders_table_heading_seller') }}</th>
            <th>{{ $t('view_orders_table_heading_task') }}</th>
            <th>{{ $t('view_orders_table_heading_price') }}</th>
            <th>{{ $t('view_orders_table_heading_purchased') }}</th>
            <th>{{ $t('view_orders_table_heading_status') }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody class="mb-md-0 mb-3">
          <tr v-for="(purchase, index) in orders" :key="index">
            <td :data-title="$t('view_orders_table_heading_seller')">
              <router-link class="text-dark  " :to="'/' + purchase.seller_username ">
                {{ purchase.seller_username }}
              </router-link>
            </td>
            <td :data-title="$t('view_orders_table_heading_task')">
              <router-link class="text-dark  " :to="'/' + purchase.seller_username + '/' + purchase.items[0].gig_slug">
                {{ purchase.items[0].gig_title }}
              </router-link>
            </td>
            <td :data-title="$t('view_orders_table_heading_price')" v-html="getDisplayPrice((purchase.price_total !== '0.00') ? purchase.price_total : purchase.price_subtotal, purchase.currency, purchase.currency_exchange_rate)"></td>
            <td :data-title="$t('view_orders_table_heading_purchased')"> {{ date(purchase.date_created) }}</td>
            <td :data-title="$t('view_orders_table_heading_status')">
              <span class="p-0">
                {{ statusTitle }}
              </span>
            </td>
            <td :data-title="$t('view_orders_table_heading_action')">
              <router-link  :to="'/my-account/orders/receive/' + purchase.hash">
                <b-button variant="primary" class="w-100" size="sm">{{$t('view_order_button')}}</b-button>
              </router-link>
              <b-button v-if="status === 0" variant="danger" class="w-100 mt-1" @click="cancelOrderModal(purchase.hash, 'pending')" size="sm">{{ $t('cancel_order') }}</b-button>
              <b-button v-if="status === 5 && !isJobTask(purchase.items[0].gig_title)" variant="outline-success" class="w-100 mt-1" @click="getBuyAgainRoute(purchase)" size="sm">{{ $t('buy_again') }}</b-button>
            </td>
          </tr>
          </tbody>
        </table>
        <Pagination v-if="paginationTotal > paginationPerPage" :perPage="paginationPerPage" :currentPage="currentPage" :total="paginationTotal"
                    v-on:paginationUpdate="getOrders"
                    class="mt-3"/>
      </div>
      <div v-else class="text-left">
        <p>{{ $t('orders_empty_heading') }}</p>
      </div>
    </div>
  </b-tab>
</template>

<script>
import ApiService from '../../apiService'
import Pagination from '../Global/Pagination'
import helper from '../../helper'

export default {
  name: 'OrderPurchaseTab',
  components: { Pagination },
  props: {
    'title': {
      type: String,
      required: true
    },
    'status': {
      type: Number,
      required: true
    },
    'statusTitle': {
      type: String,
      required: true
    }
  },
  data () {
    return {
      orders: [],
      currentPage: 0,
      paginationLoad: 0,
      paginationPerPage: 0,
      paginationTotal: 0,
      currentStatus: this.$props.status
    }
  },
  methods: {
    date (date) {
      if (date === null) {
        return this.$t('no_payment_date_message')
      }
      const newDate = new Date(date)
      return newDate.toLocaleDateString()
    },
    getOrders (page) {
      this.paginationLoad = false
      ApiService.getOrdersByStatus(page, 'buyer', this.currentStatus).then(response => {
        this.orders = response.data.data.results
        this.currentPage = response.data.data.pagination.page
        this.paginationPerPage = response.data.data.pagination.per_page
        this.paginationTotal = response.data.data.pagination.total
        this.paginationLoad = true
      }).catch(() => {
      })
    },
    cancelOrderModal (hash, tab) {
      this.$swal({
        title: this.$t('remove_task_title'),
        text: this.$t('cancel_order_text'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('cancel_button_order_confirm'),
        cancelButtonText: this.$t('cancel_button_order_cancel'),
        showLoaderOnConfirm: true,
        preConfirm: (result) => {
          if (result === true) {
            let data = { 'status': 8 }
            return ApiService.postOrders(hash, data).then(() => {
            }).catch((error) => {
              this.$swal.showValidationMessage(
                error.response.data.error_message
              )
            })
          }
        }
      }).then((result) => {
        if (result.value === true) {
          this.$swal({
            title: this.$t('success'),
            text: this.$t('order_cancelled'),
            type: 'success',
            confirmButtonText: this.$t('close_button')
          }).then((result) => {
            if (result.value === true) {
              this.getOrdersBuyer(this.currentPageBuy)
            }
          })
        }
      })
    },
    getDisplayPrice (price, currency, rate) {
      if (currency !== 'USD') {
        return helper.currencyConversion(price, 'USD', 1) + '<br>(' + helper.currencyConversion(price, currency, rate) + ')'
      }

      return helper.currencyConversion(price, currency, rate)
    },
    isCustomTask (taskTitle) {
      return taskTitle.substr(0, 8).indexOf('[CUSTOM]') > -1
    },
    isJobTask (taskTitle) {
      return taskTitle.substr(0, 5).indexOf('[Job]') > -1
    },
    getBuyAgainRoute (task) {
      if (this.isCustomTask(task.items[0].gig_title)) {
        ApiService.getTaskByUser(task.seller_username, task.items[0].gig_slug).then(response => {
          const data = response.data.data[0]
          this.$router.push('/' + data.user.username + '/' + data.original_slug)
        })
      } else {
        this.$router.push('/' + task.seller_username + '/' + task.items[0].gig_slug)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.description {
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

img {
  margin: 10px 10px;
  width: 40px;
  height: 40px;
  border-radius: 25px;
}

.container {
  min-height: 40vh;
}

.hide {
  display: none;
}

h1 small {
  color: dimgrey;
}

.collapse-header {
  cursor: pointer;
}

td {
  width: 15%;
}

td[data-title="Due Date"] .due-date-near {
  font-size: .75rem;
}

@media only screen and (max-width: 768px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
  }

  table {
    border: none;
  }

  td {
    /* Behave like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    text-align: left;
    overflow-y: visible;
    font-size: 90%;
    width: unset;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 1em;
    height: 100%;
    left: 1em;
    width: 45%;
    padding-right: 10px;
    text-align: left;
    overflow-y: hidden;
    font-weight: bold;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  /*
  Label the data
  */
  td:before {
    content: attr(data-title);
  }
}

.opacityDeclined {
  opacity: 0.4;
}

.btn-secondary {
  background-color: $brand-denim !important;
  border-color: $brand-denim !important;
}

.new-order {
  td {
    position: relative;
    border-left: 2px solid $brand-tangerine;
  }
  td[data-title="Status"] {
    animation: scaleUpBounce 1s ease-in-out;
    span {
      font-weight: bold;
      &:before {
        content: '🎉';
        padding-right: 5px !important;
      }
    }
  }
}

@include md-up {
  .new-order {
    td {
      border-left: 0;
    }
    td:first-of-type {
      position: relative;
      border-left: 2px solid $brand-tangerine;
    }
  }
  .tab-content > .active {
    overflow-x: auto;
  }
}

@keyframes scaleUpBounce {
  0% {
    transform: scale(0.5);
  }
  70% {
    transform: scale(1.2);
  }
  80% {
    transform: scale(0.9);
  }
  90% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

</style>
