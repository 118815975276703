<template>
  <b-tab :title="title" v-on:click="getOrders(1)">
    <div v-if="!paginationLoad" class="mx-auto my-5">
      <b-spinner large label="Large Spinner"></b-spinner>
      <div>{{ $t('loading_orders') }}</div>
    </div>
    <div v-else>
      <div v-if="paginationTotal > 0">
        <table class="table table-striped table-bordered add-manage-table table demo footable-loaded footable"
               data-filter="#filter" data-filter-text-only="true">
          <thead>
          <tr>
            <th>{{ $t('view_orders_table_heading_buyer') }}</th>
            <th>{{ $t('view_orders_table_heading_task') }}</th>
            <th>{{ $t('view_orders_table_heading_price') }}</th>
            <th>{{ $t('view_orders_table_heading_purchased') }}</th>
            <th v-if="[0, 1, 3, 4].includes(status)">{{ $t('due_date') }}</th>
            <th>{{ $t('view_orders_table_heading_status') }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody class="mb-md-0 mb-3">
          <tr v-for="(purchase, index) in orders" :key="index"
              v-bind:class="{'opacityDeclined' : status === 2, 'new-order' : status === 0}">
            <td :data-title="$t('view_orders_table_heading_seller')">
              <router-link class="text-dark  " :to="'/' + purchase.buyer_username ">
                {{ purchase.buyer_username }}
              </router-link>
            </td>
            <td :data-title="$t('view_orders_table_heading_task')">
              <router-link class="text-dark  " :to="'/' + purchase.seller_username + '/' + purchase.items[0].gig_slug">
                {{ purchase.items[0].gig_title }}
              </router-link>
            </td>
            <td :data-title="$t('view_orders_table_heading_price')">${{purchase.price_subtotal.toFixed(2)}} <br> {{purchase.price_etn.toFixed(2)}} ETN</td>
            <td :data-title="$t('view_orders_table_heading_purchased')"> {{ date(purchase.date_created) }}</td>

            <td v-if="[0, 1, 3, 4].includes(purchase.status[purchase.status.length - 1].status)" :data-title="$t('due_date')">
              <div v-if="dateDiffInDays(purchase.due_date) <= 3 && dateDiffInDays(purchase.due_date) > 0" class="due-date">
                <div v-if="dateDiffInDays(purchase.due_date) === 3" class="due-date-pill d-inline-flex three mb-1">{{ $t('due_date_3_left') }}</div>
                <div v-else-if="dateDiffInDays(purchase.due_date) === 2" class="due-date-pill d-inline-flex two mb-1">{{ $t('due_date_2_left') }}</div>
                <div v-else-if="dateDiffInDays(purchase.due_date) === 1" class="due-date-pill d-inline-flex one mb-1">{{ $t('due_date_1_left') }}</div>
              </div>
              <div v-if="dateDiffInDays(purchase.due_date) <= 0" class="due-date-past orders">
                <div class="d-inline-flex align-items-center one mb-1">
                  <i class="fa-solid fa-triangle-exclamation mr-1"></i>
                  <span>{{ $t('past_due') }}</span>
                </div>
              </div>
              <span class="d-block">{{ date(purchase.due_date) }}</span>
            </td>
            <td :data-title="$t('view_orders_table_heading_status')">
              <span class="p-0">
                {{ statusTitle }}
              </span>
            </td>
            <td v-if="purchase.status[purchase.status.length - 1 ].status === 2" :data-title="$t('view_orders_table_heading_action')">
              <b-button variant="light" class="w-100" :disabled="true" size="sm">{{$t('declined_button')}}</b-button>
            </td>
            <td v-else :data-title="$t('view_orders_table_heading_action')">
              <router-link  :to="'/my-account/orders/receive/' + purchase.hash">
                <b-button variant="primary" class="w-100"  size="sm">{{$t('view_order_button')}}
                </b-button>
              </router-link>
            </td>
          </tr>
          </tbody>
        </table>
        <Pagination v-if="paginationTotal > paginationPerPage" :perPage="paginationPerPage" :currentPage="currentPage" :total="paginationTotal"
                    v-on:paginationUpdate="getOrders"
                    class="mt-3"/>
      </div>
      <div v-else class="text-left">
        <p>{{ $t('orders_empty_heading') }}</p>
      </div>
    </div>
  </b-tab>
</template>

<script>
import ApiService from '../../apiService'
import Pagination from '../Global/Pagination'
import helper from '../../helper'

export default {
  name: 'OrderSaleTab',
  components: { Pagination },
  props: {
    'title': {
      type: String,
      required: true
    },
    'status': {
      type: Number,
      required: true
    },
    'statusTitle': {
      type: String,
      required: true
    },
    'identifier': {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      tabHashToLoad: ['#awaiting-acceptance', '#disputed'],
      tabIdToLoad: ['awaiting_acceptance', 'disputed'],
      username: '',
      orders: [],
      currentPage: 0,
      paginationLoad: 0,
      paginationPerPage: 0,
      paginationTotal: 0,
      currentStatus: this.$props.status,
      tabId: this.$props.identifier
    }
  },
  mounted () {
    this.preLoadTab()
  },
  methods: {
    date (date) {
      if (date === null) {
        return this.$t('no_payment_date_message')
      }
      const newDate = new Date(date)
      return newDate.toLocaleDateString()
    },
    dateDiffInDays (date) {
      return helper.dateDiffInDays(date)
    },
    preLoadTab () {
      if (this.tabHashToLoad.includes(window.location.hash) && this.tabIdToLoad.includes(this.identifier)) {
        this.getOrders()
      }
    },
    getOrders (page) {
      this.paginationLoad = false
      ApiService.getOrdersByStatus(page, 'seller', this.currentStatus).then(response => {
        this.orders = response.data.data.results
        this.currentPage = response.data.data.pagination.page
        this.paginationPerPage = response.data.data.pagination.per_page
        this.paginationTotal = response.data.data.pagination.total
        this.paginationLoad = true
        this.clearHash()
      }).catch(() => {
      })
    },
    clearHash () {
      if (this.tabHashToLoad.includes(window.location.hash)) {
        window.location.hash = ''
      }
    }
  }
}
</script>

<style lang="scss">
  .due-date {
    .due-date-pill {
      padding: 1px 7px;
      border: 1px solid;
      border-radius: 10px;
      &.three {
        border-color: $green;
        color: $green;
      }
      &.two {
        border-color: $star-yellow;
        color: $star-yellow;
      }
      &.one {
        border-color: $brand-scarlet;
        color: $brand-scarlet;
      }
    }
  }
  .due-date-past {
    font-size: .875rem;
    &.orders {
      color: $brand-scarlet;
    }
    &.review {
      font-size: 1rem;
      .fa-triangle-exclamation {
        margin-bottom: 0;
      }
    }
    .icon {
      color: $brand-scarlet;
    }
    .fa-triangle-exclamation {
      margin-bottom: 2px;
      font-size: 1rem;
    }
  }
</style>

<style lang="scss" scoped>
  .description {
    word-wrap: break-word;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  img {
    margin: 10px 10px;
    width: 40px;
    height: 40px;
    border-radius: 25px;
  }
  .due-date {
    font-size: .875rem;
  }

  .container {
    min-height: 40vh;
  }

  .hide {
    display: none;
  }

  h1 small {
    color: dimgrey;
  }

  .collapse-header {
    cursor: pointer;
  }

  td {
    width: 15%;
  }

  td[data-title="Due Date"] .due-date-near {
    font-size: .75rem;
  }

  @media only screen and (max-width: 768px) {
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
    }

    table {
      border: none;
    }

    td {
      /* Behave like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50% !important;
      text-align: left;
      overflow-y: visible;
      font-size: 90%;
      width: unset;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 1em;
      height: 100%;
      left: 1em;
      width: 45%;
      padding-right: 10px;
      text-align: left;
      overflow-y: hidden;
      font-weight: bold;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    /*
    Label the data
    */
    td:before {
      content: attr(data-title);
    }
  }

  .opacityDeclined {
    opacity: 0.4;
  }

  .btn-secondary {
    background-color: $brand-denim !important;
    border-color: $brand-denim !important;
  }

  .new-order {
    td {
      position: relative;
      border-left: 2px solid $brand-tangerine;
    }
    td[data-title="Status"] {
      animation: scaleUpBounce 1s ease-in-out;
      span {
        font-weight: bold;
        &:before {
          content: '🎉';
          padding-right: 5px !important;
        }
      }
    }
  }

  @include md-up {
    .new-order {
      td {
        border-left: 0;
      }
      td:first-of-type {
        position: relative;
        border-left: 2px solid $brand-tangerine;
      }
    }
    .tab-content > .active {
      overflow-x: auto;
    }
  }

  @keyframes scaleUpBounce {
    0% {
      transform: scale(0.5);
    }
    70% {
      transform: scale(1.2);
    }
    80% {
      transform: scale(0.9);
    }
    90% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

</style>
