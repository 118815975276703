<template>
  <div class="view-orders">
    <b-container v-if="ordersFetched" class="mb-3">
      <b-row class="text-center">
        <h1 class=" my-3 px-3 p-sm-0 py-0 py-md-3 page-heading w-100">{{ $t('view_sales_heading') }}</h1>
      </b-row>
      <div v-if="paginationSoldTotal === 0" class="mx-auto no-saved-tasks">
        <h1>{{ $t('orders_empty_heading') }}</h1>
        <i class="mb-3 fa-solid fa-basket-shopping fa-4x" aria-hidden="true"></i>
      </div>
      <div v-else >
        <div class="row justify-content-center mb-4">
          <StatsBox :title="$t('in_progress')" :total="orderStatusTotal[3] || 0" v-on:clicked="setTabIndex(2)" />
          <StatsBox :title="$t('completed')" :total="orderStatusTotal[5] || 0" v-on:clicked="setTabIndex(4)" />
          <StatsBox :title="$t('disputed')" :total="orderStatusTotal[7] || 0" v-on:clicked="setTabIndex(6)" />
        </div>
        <b-card no-body>
          <b-tabs v-model="soldTabIndex" card>
            <b-tab :title="$t('all')" v-on:click="getOrdersSeller(1)">
              <SalesTable :moreInfo="true" class="view-my-sales" />
            </b-tab>
            <OrderSale identifier="awaiting_acceptance" :title="$t('awaiting_acceptance')" :status="0" :status-title="$t('new_order')"/>
            <OrderSale :title="$t('in_progress')" :status="3" :status-title="$t('in_progress')"/>
            <OrderSale :title="$t('delivered')" :status="4" :status-title="$t('delivered')"/>
            <OrderSale :title="$t('completed')" :status="5" :status-title="$t('completed')"/>
            <OrderSale :title="$t('declined')" :status="2" :status-title="$t('job_declined')"/>
            <OrderSale identifier="disputed" :title="$t('disputed')" :status="7" :status-title="$t('disputed')"/>
            <OrderSale :title="$t('cancelled')" :status="8" :status-title="$t('cancelled')"/>
            <OrderSale :title="$t('expired')" :status="9" :status-title="$t('expired')"/>
          </b-tabs>
        </b-card>
      </div>
    </b-container>
    <div v-else class="mx-auto my-5">
      <b-spinner large label="Large Spinner"></b-spinner>
      <div>{{ $t('loading_orders') }}</div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../apiService.js'
import OrderSale from '../../components/Tabs/OrderSale'
import SalesTable from '../../components/MyAccount/SalesTable'
import StatsBox from '../../components/MyAccount/StatsBox'
import { mapActions } from 'vuex'

export default {
  name: 'ViewMySales',
  components: { OrderSale, SalesTable, StatsBox },
  data () {
    return {
      paginationSoldLoad: false,
      ordersFetched: false,
      showBoughtCollapse: false,
      ordersSeller: [],
      currentPageSold: 0,
      paginationSoldPerPage: 0,
      paginationSoldTotal: 0,
      soldTabIndex: null,
      orderStatusTotal: {}
    }
  },
  mounted () {
    this.setNewOrdersCount(0)
    this.getOrders()
    ApiService.getTotalOrdersByStatus('seller').then((resp) => {
      this.orderStatusTotal = resp.data.data
    }).catch({

    })
  },
  watch: {
    '$route.hash': function (id) {
      this.setTabIndex()
    }
  },
  methods: {
    ...mapActions(['setNewOrdersCount']),
    date (date) {
      if (date === null) {
        return this.$t('no_payment_date_message')
      }
      const newDate = new Date(date)
      return newDate.toLocaleDateString()
    },
    getOrders () {
      this.setTabIndex()
      this.getOrdersSeller(1)
    },
    getOrdersSeller (page) {
      this.paginationSoldLoad = false
      ApiService.getOrders(page, 'seller').then(response => {
        this.ordersSeller = response.data.data.results
        this.currentPageSold = response.data.data.pagination.page
        this.paginationSoldPerPage = response.data.data.pagination.per_page
        this.paginationSoldTotal = response.data.data.pagination.total
        this.paginationSoldLoad = true
        this.ordersFetched = true
      }).catch(() => {
      })
    },
    setTabIndex (tabIndex = null) {
      if (window.location.hash === '#awaiting-acceptance') {
        this.soldTabIndex = 1
      } else if (window.location.hash === '#disputed') {
        this.soldTabIndex = 6
      } else if (tabIndex !== null) {
        this.soldTabIndex = tabIndex
        this.$nextTick(() => {
          const el = document.querySelector('.nav-tabs .nav-item a.active')
          if (el) {
            el.click()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
  .due-date {
    .due-date-pill {
      padding: 1px 7px;
      border: 1px solid;
      border-radius: 10px;
      &.three {
        border-color: $green;
        color: $green;
      }
      &.two {
        border-color: $star-yellow;
        color: $star-yellow;
      }
      &.one {
        border-color: $brand-scarlet;
        color: $brand-scarlet;
      }
    }
  }
  .due-date-past {
    font-size: .875rem;
    &.orders {
      color: $brand-scarlet;
    }
    &.review {
      font-size: 1rem;
      .fa-exclamation-circle {
        margin-bottom: 0;
      }
    }
    .icon {
      color: $brand-scarlet;
    }
    .fa-exclamation-circle {
      margin-bottom: 2px;
      font-size: 1rem;
    }
  }
</style>

<style lang="scss" scoped>
/deep/ .view-my-sales {
  .description {
    word-wrap: break-word;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  img {
    margin: 10px 10px;
    width: 40px;
    height: 40px;
    border-radius: 25px;
  }

  .due-date {
    font-size: .875rem;
  }

  .container {
    min-height: 40vh;
  }

  .hide {
    display: none;
  }

  h1 small {
    color: dimgrey;
  }

  .collapse-header {
    cursor: pointer;
  }

  td {
    width: 15%;
  }

  td[data-title="Due Date"] .due-date-near {
    font-size: .75rem;
  }

  @media only screen and (max-width: 768px) {
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
    }

    table {
      border: none;
    }

    td {
      /* Behave like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50% !important;
      text-align: left;
      overflow-y: visible;
      font-size: 90%;
      width: unset;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 1em;
      height: 100%;
      left: 1em;
      width: 45%;
      padding-right: 10px;
      text-align: left;
      overflow-y: hidden;
      font-weight: bold;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    /*
    Label the data
    */
    td:before {
      content: attr(data-title);
    }
  }

  .opacityDeclined {
    opacity: 0.4;
  }

  .btn-secondary {
    background-color: $brand-denim !important;
    border-color: $brand-denim !important;
  }

  .new-order {
    td {
      position: relative;
      border-left: 2px solid $brand-tangerine;
    }

    td[data-title="Status"] {
      animation: scaleUpBounce 1s ease-in-out;

      span {
        font-weight: bold;

        &:before {
          content: '🎉';
          padding-right: 5px !important;
        }
      }
    }
  }

  @include md-up {
    .new-order {
      td {
        border-left: 0;
      }

      td:first-of-type {
        position: relative;
        border-left: 2px solid $brand-tangerine;
      }
    }
    .tab-content > .active {
      overflow-x: auto;
    }
  }

  @keyframes scaleUpBounce {
    0% {
      transform: scale(0.5);
    }
    70% {
      transform: scale(1.2);
    }
    80% {
      transform: scale(0.9);
    }
    90% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
}
</style>
