<template>
  <div v-if="paginationBuyerTotal > 0">
    <table v-if="paginationBuyerLoad" class="table table-striped table-bordered add-manage-table table demo footable-loaded footable"
           data-filter="#filter" data-filter-text-only="true">
      <thead>
      <tr v-if="moreInfo">
        <th>{{ $t('view_orders_table_heading_seller') }}</th>
        <th>{{ $t('view_orders_table_heading_task') }}</th>
        <th>{{ $t('view_orders_table_heading_price') }}</th>
        <th>{{ $t('view_orders_table_heading_purchased') }}</th>
        <th>{{ $t('view_orders_table_heading_status') }}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(purchase, index) in ordersBuyer" :key="index" class="mb-md-0 mb-3" v-bind:class="{'opacityDeclined' : (purchase.status[purchase.status.length - 1].status === 2), 'new-order' : purchase.status[purchase.status.length - 1].status === 4}">
        <td :data-title="$t('view_orders_table_heading_seller')">
          <router-link class="text-dark  " :to="'/' + purchase.seller_username ">
            {{ purchase.seller_username }}
          </router-link>
        </td>
        <td :data-title="$t('view_orders_table_heading_task')">
          <router-link class="text-dark  " :to="'/' + purchase.seller_username + '/' + purchase.items[0].gig_slug">
            {{ purchase.items[0].gig_title }}
          </router-link>
        </td>
        <td :data-title="$t('view_orders_table_heading_price')" v-html="getDisplayPrice((purchase.price_total !== '0.00') ? purchase.price_total : purchase.price_subtotal, purchase.currency, purchase.currency_exchange_rate)"></td>
        <td v-if="moreInfo" :data-title="$t('view_orders_table_heading_purchased')"> {{ date(purchase.date_created) }}</td>
        <td :data-title="$t('view_orders_table_heading_status')">
          <span v-if="purchase.buyer_username === username && purchase.status[purchase.status.length - 1].status === 0" class="p-0">
            {{ $t('job_waiting_for_decision') }}
          </span>
          <span v-else-if="purchase.seller_username === username && purchase.status[purchase.status.length - 1].status === 0" class="p-0">
            {{ $t('new_order') }}
          </span>
          <span v-else-if="purchase.status[purchase.status.length - 1].status === 1" class="p-0">
            {{ $t('in_progress') }}
          </span>
          <span v-else-if="purchase.status[purchase.status.length - 1].status === 2" class="p-0">
            {{ $t('job_declined') }}
          </span>
          <span v-else-if="purchase.status[purchase.status.length - 1].status === 3" class="p-0">
            {{ $t('in_progress') }}
          </span>
          <span v-else-if="purchase.status[purchase.status.length - 1].status === 4" class="p-0">
            {{ $t('delivered') }}
          </span>
          <span v-else-if="purchase.status[purchase.status.length - 1].status === 5" class="p-0">
            {{ $t('completed') }}
          </span>
          <span v-else-if="purchase.status[purchase.status.length - 1].status === 6" class="p-0">
            {{ $t('refund') }}
          </span>
          <span v-else-if="purchase.status[purchase.status.length - 1].status === 7" class="p-0">
            {{ $t('disputed') }}
          </span>
          <span v-else-if="purchase.status[purchase.status.length - 1].status === 8" class="p-0">
            {{ $t('cancelled') }}
          </span>
          <span v-else-if="purchase.status[purchase.status.length - 1].status === 9" class="p-0">
            {{ $t('expired') }}
          </span>
          <span v-else class="p-0">
            {{ $t('unknown') }}
          </span>
        </td>
        <td v-if="moreInfo" :data-title="$t('view_orders_table_heading_action')">
          <router-link  :to="'/my-account/orders/receive/' + purchase.hash">
            <b-button variant="primary" class="w-100" size="sm">{{$t('view_order_button')}}</b-button>
          </router-link>
          <b-button v-if="purchase.buyer_username === username && purchase.status[purchase.status.length - 1].status === 0" variant="danger" class="w-100 mt-1" @click="cancelOrderModal(purchase.hash, 'all')" size="sm">{{ $t('cancel_order') }}</b-button>
          <b-button v-if="purchase.buyer_username === username && purchase.status[purchase.status.length - 1].status === 5 && !isJobTask(purchase.items[0].gig_title)" variant="outline-success" class="w-100 mt-1" @click="getBuyAgainRoute(purchase)" size="sm">{{ $t('buy_again') }}</b-button>
        </td>
      </tr>
      </tbody>
    </table>
    <div v-else class="mx-auto my-5">
      <b-spinner large label="Large Spinner"></b-spinner>
      <div>{{ $t('loading_orders') }}</div>
    </div>
    <!--/.row-box End-->
    <Pagination v-if="(paginationBuyerTotal > paginationBuyerPerPage) && moreInfo" :perPage="paginationBuyerPerPage" :currentPage="currentPageBuy" :total="paginationBuyerTotal"
                v-on:paginationUpdate="getOrdersBuyer"
                class="mt-3"/>
  </div>
  <div v-else :class="moreInfo ? 'text-left' : 'text-center mt-3'">
    <p>{{ $t('orders_empty_heading') }}</p>
  </div>
</template>
<script>
import ApiService from '../../apiService'
import Pagination from '../Global/Pagination'
import helper from '../../helper'
export default {
  name: 'PurchasesTable',
  components: { Pagination },
  props: {
    moreInfo: Boolean,
    maxPerPage: Number
  },
  data () {
    return {
      username: '',
      paginationBuyerLoad: false,
      paginationBuyerPendingLoad: false,
      ordersFetched: false,
      ordersBuyer: [],
      ordersBuyerPending: [],
      currentPageBuy: 0,
      currentPageBuyPending: 0,
      paginationBuyerPerPage: 0,
      paginationBuyerPendingPerPage: 0,
      paginationBuyerTotal: 0,
      paginationBuyerPendingTotal: 0
    }
  },
  mounted () {
    this.getOrders()
  },
  methods: {
    date (date) {
      if (date === null) {
        return this.$t('no_payment_date_message')
      }
      const newDate = new Date(date)
      return newDate.toLocaleDateString()
    },
    dateDiffInDays (date) {
      return helper.dateDiffInDays(date)
    },
    getOrders () {
      this.username = localStorage.getItem('username')
      this.getOrdersBuyer(1)
    },
    getOrdersBuyer (page) {
      this.paginationBuyerLoad = false
      ApiService.getOrders(page, 'buyer').then(response => {
        this.ordersBuyer = response.data.data.results
        this.currentPageBuy = response.data.data.pagination.page
        this.paginationBuyerPerPage = response.data.data.pagination.per_page
        this.paginationBuyerTotal = response.data.data.pagination.total
        this.paginationBuyerLoad = true
        this.ordersFetched = true
      }).catch(() => {
      })
    },
    cancelOrderModal (hash, tab) {
      this.$swal({
        title: this.$t('remove_task_title'),
        text: this.$t('cancel_order_text'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('cancel_button_order_confirm'),
        cancelButtonText: this.$t('cancel_button_order_cancel'),
        showLoaderOnConfirm: true,
        preConfirm: (result) => {
          if (result === true) {
            let data = { 'status': 8 }
            return ApiService.postOrders(hash, data).then(() => {
            }).catch((error) => {
              this.$swal.showValidationMessage(
                error.response.data.error_message
              )
            })
          }
        }
      }).then((result) => {
        if (result.value === true) {
          this.$swal({
            title: this.$t('success'),
            text: this.$t('order_cancelled'),
            type: 'success',
            confirmButtonText: this.$t('close_button')
          }).then((result) => {
            if (result.value === true) {
              this.getOrdersBuyer(this.currentPageBuy)
            }
          })
        }
      })
    },
    getDisplayPrice (price, currency, rate) {
      if (currency !== 'USD') {
        return helper.currencyConversion(price, 'USD', 1) + '<br>(' + helper.currencyConversion(price, currency, rate) + ')'
      }
      return helper.currencyConversion(price, currency, rate)
    },
    isCustomTask (taskTitle) {
      return taskTitle.substr(0, 8).indexOf('[CUSTOM]') > -1
    },
    isJobTask (taskTitle) {
      return taskTitle.substr(0, 5).indexOf('[Job]') > -1
    },
    getBuyAgainRoute (task) {
      if (this.isCustomTask(task.items[0].gig_title)) {
        ApiService.getTaskByUser(task.seller_username, task.items[0].gig_slug).then(response => {
          const data = response.data.data[0]
          this.$router.push('/' + data.user.username + '/' + data.original_slug)
        })
      } else {
        this.$router.push('/' + task.seller_username + '/' + task.items[0].gig_slug)
      }
    }
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix
      } else {
        return text
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {
    min-height: 40vh;
  }

  td[data-title="Due Date"] .due-date-near {
    font-size: .75rem;
  }

  @media only screen and (max-width: 768px) {
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
    }

    table {
      border: none;
    }

    td {
      /* Behave like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50% !important;
      text-align: left;
      overflow-y: visible;
      font-size: 90%;
      width: unset;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 1em;
      height: 100%;
      left: 1em;
      width: 45%;
      padding-right: 10px;
      text-align: left;
      overflow-y: hidden;
      font-weight: bold;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    /*
    Label the data
    */
    td:before {
      content: attr(data-title);
    }
  }

  .opacityDeclined {
    opacity: 0.4;
  }

  .new-order {
    td {
      position: relative;
      border-left: 2px solid $brand-tangerine;
    }
    td[data-title="Status"] {
      animation: scaleUpBounce 1s ease-in-out;
      span {
        font-weight: bold;
        &:before {
          content: '🎉';
          padding-right: 5px !important;
        }
      }
    }
  }

  @include md-up {
    .new-order {
      td {
        border-left: 0;
      }
      td:first-of-type {
        position: relative;
        border-left: 2px solid $brand-tangerine;
      }
    }
    .tab-content > .active {
      overflow-x: auto;
    }
  }

  @keyframes scaleUpBounce {
    0% {
      transform: scale(0.5);
    }
    70% {
      transform: scale(1.2);
    }
    80% {
      transform: scale(0.9);
    }
    90% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

</style>
