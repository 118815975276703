<template>
  <div class="container py-3">
    <div class="mb-5 mt-1">
      <Breadcrumb v-if="breadcrumbs.length > 0" :breadcrumbList="breadcrumbs"/>
    </div>
    <b-alert show variant="success" v-if="successSwitch" class="mt-3 mb-0 w-100 mx-3 mb-4">
      {{ successMessage }}
    </b-alert>
    <b-card>
      <div class="row" v-if="deliveriesLoaded">
        <div class="col-12">
          <h3 class="text-left">{{ $t('deliver_task') }}</h3>
          <h6 class="text-left" v-if="deliveries.length > 0">{{ $t('saved_uploads') }}</h6>
          <div class="row" v-if="deliveries.length > 0">
            <div class="col-lg-3 col-md-4" v-for="(delivery, index) in deliveries" v-bind:key="index">
              <b-card-group deck class="mb-3">
                <b-card footer-tag="footer">
                  <b-card-text>
                    <i class="fa-regular fa-file fa-2x"></i>
                  </b-card-text>
                  <div class="text-muted text-break text-truncate">
                    <small v-b-popover.hover.top="delivery.filename">{{ delivery.filename }}</small>
                  </div>
                  <div slot="footer">
                    <a v-on:click="lookup(delivery.hash)" target="_blank">
                      <span class="float-right download">
                          <i v-b-popover.hover.top="$t('download')" class="fa-solid fa-download shadow-sm" aria-hidden="true"></i>
                      </span>
                    </a>
                    <a v-on:click="deleteSavedDeliveryAsset(delivery.hash)" target="_blank">
                      <span class="float-right download pr-3">
                          <i v-b-popover.hover.top="$t('delete')" class="fa-solid fa-trash-can shadow-sm" aria-hidden="true"></i>
                      </span>
                    </a>
                  </div>
                </b-card>
              </b-card-group>
            </div>
          </div>
          <div id="task-form-error-details" class="p-2 alert alert-danger text-left" v-if="errorMessageSwitch">
            <p>{{ $t('delivery_error') }}</p>
            <ul>
              <li>{{ errorMessage }}</li>
            </ul>
          </div>
          <div class="box-title bottomBorder mb-3">
            <div class="inner">
              <h2 class="mb-0 mt-3">{{ $t('delivery_messages') }}</h2>
            </div>
          </div>
          <b-form-textarea
            id="textarea"
            class="mb-2"
            v-model="deliverDetails"
            :placeholder="$t('delivery_input_box_placeholder')"
            rows="3"
          ></b-form-textarea>

          <div class="box-title bottomBorder mb-3">
            <div class="inner">
              <h2 class="mb-0 mt-3">{{ $t('upload_completed_work') }}</h2>
            </div>
          </div>
          <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" :useCustomSlot=true
                        :awss3="awss3"
                        v-on:vdropzone-removed-file='dropzoneRemoved'
                        v-on:vdropzone-error="dropzoneError"
                        v-on:vdropzone-files-added="preFileUpload"
                        v-on:vdropzone-queue-complete="dropzoneUploadComplete()"
                        v-on:vdropzone-s3-upload-error="s3UploadError"
                        v-on:vdropzone-s3-upload-success="s3UploadSuccess"
          >
            <div class="dropzone-custom-content text-muted">
              <i class="fa-solid fa-cloud-arrow-up fa-3x"></i>
              <br/>
              {{ $t('dropzone_placeholder') }}
            </div>
          </vue-dropzone>

          <div class="w-100 pt-1">
            <em class="inlineHint text-left">{{ $t('upload_advice').join(' ') }}</em>
            <em class="inlineHint text-left">{{ $t('delivery_instructions') }}</em>
          </div>

          <b-button v-if="!uploading" variant="primary" class="mt-3 mr-5" @click="handleAssetUpload" :disabled="fileUploading">{{ $t('save_delivery') }}</b-button>
          <b-button v-if="!uploading" variant="primary" class="mt-3" @click="handleSubmit" :disabled="fileUploading">{{ $t('publish_delivery') }}</b-button>
          <b-button v-else type="submit" class="w-50 mt-2" variant="primary" disabled="disabled">
            <b-spinner class="" small label="Large Spinner w-25"></b-spinner>
          </b-button>
        </div>
      </div>
      <div v-else class="mx-auto my-5">
        <b-spinner large label="Large Spinner"></b-spinner>
        <div>{{ $t('loading_delivery_form') }}</div>
      </div>
    </b-card>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { mapActions } from 'vuex'
import ApiService from '../../apiService.js'
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'

export default {
  name: 'DeliverTask',
  data () {
    return {
      order: [],
      fileUploading: false,
      successSwitch: false,
      successMessage: '',
      orderHash: this.$route.params.hash,
      deliveries: [],
      errorMessage: '',
      deliverDetails: '',
      errorMessageSwitch: false,
      attachments: [],
      awss3: {
        signingURL: ApiService.getTokenUrl('delivery'),
        headers: {},
        params: {
          'hash': this.$route.params.hash
        },
        sendFileToServer: false
      },
      dropzoneOptions: {
        paramName: 'file',
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        maxFilesize: 500,
        maxFiles: 10,
        parallelUploads: 2,
        autoProcessQueue: true,
        timeout: 0,
        retryChunksLimit: 1,
        addRemoveLinks: true,
        dictCancelUpload: this.$t('cancel'),
        dictRemoveFile: this.$t('delete'),
        renameFile (file) {
          // eslint-disable-next-line no-return-assign
          return new Date().valueOf() + '_' + file.name.replace(/[^0-9a-z._-]/gi, '')
        }
      },
      uploading: false,
      asset_validation: false,
      deliveriesLoaded: false,
      linking: false,
      hash: this.$route.params.hash,
      breadcrumbs: []
    }
  },
  components: {
    Breadcrumb,
    vueDropzone
  },
  mounted () {
    this.getSavedAssets()
    this.getOrder()
    this.updateToken()
  },
  methods: {
    ...mapActions([
      'fetchNewAccessToken'
    ]),
    updateToken () {
      this.fetchNewAccessToken().then(() => {
        this.awss3.headers = {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        }
        this.deliveriesLoaded = true
      })
    },
    ...mapActions([
      'fetchNewAccessToken'
    ]),
    getOrder () {
      ApiService.getOrder(this.$route.params.hash).then(response => {
        this.order = response.data.data
        this.breadcrumbs = [
          { 'name': this.$t('order'), 'link': this.$router.resolve({ name: 'view_my_sales' }).href },
          { 'name': this.order.hash, 'link': this.$router.resolve({ name: 'receive_task', params: { hash: this.order.hash } }).href },
          { 'name': this.$t('deliver_task'), 'link': this.$router.resolve({ name: 'deliver_task', params: { hash: this.order.hash } }).href }
        ]
      }).catch(() => {
      })
    },
    getSavedAssets () {
      ApiService.getSavedAssets(this.$route.params.hash).then(response => {
        this.deliveries = response.data.data
      }).catch(() => {
      })
    },
    lookup (hash) {
      ApiService.getDeliveryAttachment(hash).then(response => {
        window.open(response.data.data, '_blank')
      })
    },
    dropzoneUploadComplete () {
      this.fileUploading = false
    },
    dropzoneRemoved (file) {
      let array = this.attachments
      for (let i = 0; i < array.length; i++) {
        let imageName = array[i].split('%2F').pop()
        if (imageName === file.upload.filename) {
          array.splice(i, 1)
          break
        }
      }
      this.attachments = array
      this.asset_validation = this.$refs.myVueDropzone.dropzone.getQueuedFiles().length === 0
    },
    dropzoneError (file, errorMessage) {
      this.fileUploading = false
      this.errorMessageSwitch = true
      this.errorMessage = errorMessage
    },
    preFileUpload (file, xhr, formData) {
      this.fileUploading = true
      this.errorMessageSwitch = false
    },
    handleAssetUpload () {
      this.successSwitch = false
      this.errorMessageSwitch = false
      this.uploading = true
      if (this.attachments.length === 0) {
        this.errorMessage = this.$t('deliver_error')
        this.errorMessageSwitch = true
        this.uploading = false
      } else {
        this.submitUrls(false)
      }
    },
    handleSubmit () {
      this.successSwitch = false
      this.errorMessageSwitch = false
      this.uploading = true
      if (this.attachments.length === 0 && this.deliveries.length === 0) {
        this.errorMessage = this.$t('deliver_error')
        this.errorMessageSwitch = true
        this.uploading = false
      } else if (this.attachments.length > 0) {
        this.submitUrls(true)
      } else {
        this.linkAssets(true)
      }
    },
    submitUrls (link) {
      ApiService.saveDeliveryAssets(this.$route.params.hash, this.attachments).then(response => {
        this.deliveries = response.data.data
        this.deliveriesLoaded = true
        this.linkAssets(link)
      }).catch((error) => {
        this.errorMessageSwitch = true
        this.uploading = false
        if (error.response.data.code === 'HTTP-400') {
          this.errorMessage = error.response.data.errors.errors[0]
        } else {
          this.errorMessage = error.response.data.error_message
        }
      })
    },
    linkAssetsApi () {
      ApiService.groupOrders(this.hash).then(response => {
        this.successMessage = this.$t('upload_delivered')
        this.successSwitch = true
        this.$refs.myVueDropzone.dropzone.removeAllFiles(true)
        this.deliveries = []
        this.uploading = false
      }).catch(() => {
      })
    },
    linkAssets (linking) {
      if (linking) {
        if (this.deliverDetails.length > 0) {
          this.sendMessage()
        } else {
          this.linkAssetsApi()
        }
      } else {
        this.successMessage = this.$t('upload_successful')
        this.successSwitch = true
        this.$refs.myVueDropzone.dropzone.removeAllFiles(true)
        this.getSavedAssets()
        this.uploading = false
      }
    },
    deleteSavedDeliveryAsset (hash) {
      this.successSwitch = false
      ApiService.deleteSavedDeliveryAsset(hash).then(response => {
        this.successMessage = this.$t('upload_deleted')
        this.successSwitch = true
        this.getSavedAssets()
      }).catch(() => {
      })
    },
    sendMessage () {
      ApiService.postMessage(this.order.thread.hash, this.deliverDetails).then(response => {
        this.deliverDetails = ''
        this.linkAssetsApi()
      }).catch((error) => {
        this.$refs.myVueDropzone.dropzone.removeAllFiles(true)
        this.getSavedAssets()
        this.errorMessageSwitch = true
        this.uploading = false
        let errors = error.response.data.errors.children
        if (errors.message.errors.length > 0) {
          this.errorMessage = errors.message.errors[0]
        }
      })
    },
    s3UploadError (errorMessage) {
      this.fileUploading = false
      this.errorMessageSwitch = true
      this.errorMessage = errorMessage
    },
    s3UploadSuccess (s3ObjectLocation) {
      this.attachments.push(s3ObjectLocation)
    }
  }
}
</script>

<style scoped lang="scss">
#task-form-error-details ul li {
  list-style-type: disc;
  margin-left: 3em;
}

/deep/ .dropzone {
  border: 2px dashed #dedede;
  border-radius: 5px;
  background: #f5f5f5;
  overflow: auto;

  i {
    font-size: 5rem;
    color: #bbb;
  }

  .dz-message {
    color: #999;
    font-weight: 600;
    text-transform: uppercase;
  }

  .dz-preview {
    &.dz-image-preview {
      background-color: transparent !important;
      float: left;
      margin: 0;
      padding: 8px;
    }

    .dz-progress {
      .dz-upload {
        background: #28A745;
      }

      border: 1px solid white;
    }
  }
}
</style>
