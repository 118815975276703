<template>
  <div v-if="paginationSoldTotal > 0">
    <table v-if="paginationSoldLoad" class="table table-striped table-bordered add-manage-table"
           data-filter="#filter" data-filter-text-only="true">
      <thead>
      <tr v-if="moreInfo">
        <th>{{ $t('view_orders_table_heading_buyer') }}</th>
        <th>{{ $t('view_orders_table_heading_task') }}</th>
        <th>{{ $t('view_orders_table_heading_price') }}</th>
        <th>{{ $t('view_orders_table_heading_purchased') }}</th>
        <th>{{ $t('due_date') }}</th>
        <th>{{ $t('view_orders_table_heading_status') }}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(purchase, index) in ordersSeller" :key="index" class="mb-md-0 mb-3 new-sale"
          v-bind:class="{'opacityDeclined' : (purchase.status[purchase.status.length - 1].status === 2), 'new-order' : purchase.seller_username === username && purchase.status[purchase.status.length - 1].status === 0}">
        <td :data-title="$t('view_orders_table_heading_seller')">
          <router-link class="text-dark  " :to="'/' + purchase.buyer_username ">
            {{ purchase.buyer_username }}
          </router-link>
        </td>
        <td :data-title="$t('view_orders_table_heading_task')">
          <router-link class="text-dark  " :to="'/' + purchase.seller_username + '/' + purchase.items[0].gig_slug">
            {{ purchase.items[0].gig_title | truncate(50, '...') }}
          </router-link>
        </td>
        <td :data-title="$t('view_orders_table_heading_price')">${{ purchase.price_subtotal.toFixed(2) }} <br> {{ purchase.price_etn.toFixed(2) }} ETN</td>
        <td v-if="moreInfo" :data-title="$t('view_orders_table_heading_purchased')"> {{ date(purchase.date_created) }}</td>
        <td v-if="moreInfo" :data-title="$t('due_date')">
          <div
            v-if="dateDiffInDays(purchase.due_date) <= 3 && (purchase.status[purchase.status.length - 1].status === 0 || purchase.status[purchase.status.length - 1].status === 1 || purchase.status[purchase.status.length - 1].status === 3 || purchase.status[purchase.status.length - 1].status === 4)"
            class="due-date">
            <div v-if="dateDiffInDays(purchase.due_date) === 3" class="due-date-pill d-inline-flex three mb-1">{{ $t('due_date_3_left') }}</div>
            <div v-if="dateDiffInDays(purchase.due_date) === 2" class="due-date-pill d-inline-flex two mb-1">{{ $t('due_date_2_left') }}</div>
            <div v-if="dateDiffInDays(purchase.due_date) === 1" class="due-date-pill d-inline-flex one mb-1">{{ $t('due_date_1_left') }}</div>
          </div>
          <div v-if="dateDiffInDays(purchase.due_date) <= 0 && ([0, 1, 3, 4].includes(purchase.status[purchase.status.length - 1].status))" class="due-date-past orders">
            <div class="d-inline-flex align-items-center one mb-1">
              <i class="fa-solid fa-triangle-exclamation mr-1"></i>
              <span>{{ $t('past_due') }}</span>
            </div>
          </div>
          <span class="d-block">{{ date(purchase.due_date) }}</span>
        </td>
        <td :data-title="$t('view_orders_table_heading_status')">
          <span v-if="purchase.buyer_username === username && purchase.status[purchase.status.length - 1].status === 0" class="p-0 status-waiting">
            {{ $t('job_waiting_for_decision') }}
          </span>
          <span v-else-if="purchase.seller_username === username && purchase.status[purchase.status.length - 1].status === 0" class="p-0 status-new">
            {{ $t('new_order') }}
          </span>
          <span v-else-if="purchase.status[purchase.status.length - 1].status === 1" class="p-0 status-in-progress">
            {{ $t('in_progress') }}
          </span>
          <span v-else-if="purchase.status[purchase.status.length - 1].status === 2" class="p-0 status-declined">
            {{ $t('job_declined') }}
          </span>
          <span v-else-if="purchase.status[purchase.status.length - 1].status === 3" class="p-0 status-in-progress">
            {{ $t('in_progress') }}
          </span>
          <span v-else-if="purchase.status[purchase.status.length - 1].status === 4" class="p-0 status-delivered">
            {{ $t('delivered') }}
          </span>
          <span v-else-if="purchase.status[purchase.status.length - 1].status === 5" class="p-0 status-completed">
            {{ $t('completed') }}
          </span>
          <span v-else-if="purchase.status[purchase.status.length - 1].status === 6" class="p-0 status-refund">
            {{ $t('refund') }}
          </span>
          <span v-else-if="purchase.status[purchase.status.length - 1].status === 7" class="p-0 status-disputed">
            {{ $t('disputed') }}
          </span>
          <span v-else-if="purchase.status[purchase.status.length - 1].status === 8" class="p-0 status-cancelled">
            {{ $t('cancelled') }}
          </span>
          <span v-else-if="purchase.status[purchase.status.length - 1].status === 9" class="p-0 status-expired">
            {{ $t('expired') }}
          </span>
          <span v-else class="p-0 status-unknown">
            {{ $t('unknown') }}
          </span>
        </td>
        <td v-if="moreInfo" :data-title="$t('view_orders_table_heading_action')">
          <b-button variant="light" class="w-100" v-if="purchase.status[purchase.status.length - 1].status === 2" :disabled="true" size="sm">
            {{ $t('declined_button') }}
          </b-button>
          <router-link :to="'/my-account/orders/receive/' + purchase.hash">
            <b-button variant="primary" class="w-100" v-if="purchase.status[purchase.status.length - 1].status === 0" size="sm">{{ $t('view_order_button') }}</b-button>
            <b-button variant="secondary" class="w-100" v-else size="sm">{{ $t('view_order_button') }}</b-button>
          </router-link>
        </td>
      </tr>
      </tbody>
    </table>
    <div v-else class="mx-auto my-5">
      <b-spinner large label="Large Spinner"></b-spinner>
      <div>{{ $t('loading_orders') }}</div>
    </div>
    <Pagination v-if="(paginationSoldTotal > paginationSoldPerPage) && moreInfo" :perPage="paginationSoldPerPage" :currentPage="currentPageSold" :total="paginationSoldTotal"
                v-on:paginationUpdate="getOrdersSeller"
                class="mt-3"/>
  </div>
  <div v-else class="text-center pt-3">
    <p>{{ $t('orders_empty_heading') }}</p>
  </div>
</template>

<script>
import ApiService from '../../apiService.js'
import Pagination from '../Global/Pagination'
import helper from '../../helper'

export default {
  name: 'SalesTable',
  components: { Pagination },
  props: {
    moreInfo: Boolean,
    maxPerPage: Number
  },
  data () {
    return {
      username: '',
      paginationSoldLoad: false,
      ordersFetched: false,
      showBoughtCollapse: false,
      ordersSeller: [],
      currentPageSold: 0,
      paginationSoldPerPage: 0,
      paginationSoldTotal: 0,
      soldTabIndex: null
    }
  },
  mounted () {
    this.getOrders()
  },
  watch: {
    '$route.hash': function (id) {
      this.setTabIndex()
    }
  },
  methods: {
    date (date) {
      if (date === null) {
        return this.$t('no_payment_date_message')
      }
      const newDate = new Date(date)
      return newDate.toLocaleDateString()
    },
    dateDiffInDays (date) {
      return helper.dateDiffInDays(date)
    },
    getOrders () {
      this.username = localStorage.getItem('username')
      this.setTabIndex()
      this.getOrdersSeller(1)
    },
    getOrdersSeller (page) {
      this.paginationSoldLoad = false
      ApiService.getOrders(page, 'seller').then(response => {
        this.ordersSeller = response.data.data.results
        this.currentPageSold = response.data.data.pagination.page
        this.paginationSoldPerPage = response.data.data.pagination.per_page
        this.paginationSoldTotal = response.data.data.pagination.total
        this.paginationSoldLoad = true
        this.ordersFetched = true
      }).catch(() => {
      })
    },
    setTabIndex () {
      if (window.location.hash === '#awaiting-acceptance') {
        this.soldTabIndex = 1
      } else if (window.location.hash === '#disputed') {
        this.soldTabIndex = 6
      }
    }
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix
      } else {
        return text
      }
    }
  }
}
</script>
