<template>
  <div class="py-md-2 py-md-2 d-flex flex-md-column justify-content-between align-items-center rounded cursor-pointer align-middle my-1 mx-md-3 border"
       :class="[isWarning ? 'bg-warning' : 'bg-white', getColumnClass]"
       @click="onClick()"
  >
    <span class="h1 m-0">{{ getTotal }}</span>
    <span class="font-weight-bold">{{ getTitle }}</span>
  </div>
</template>

<script>
export default {
  name: 'StatsBox',
  props: {
    title: String,
    total: Number,
    warning: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    },
    columnSize: {
      type: Number,
      required: false,
      default () {
        return 10
      }
    },
    columnMdSize: {
      type: Number,
      required: false,
      default () {
        return 2
      }
    }
  },
  methods: {
    onClick () {
      this.$emit('clicked')
    }
  },
  computed: {
    getTotal () {
      return this.total
    },
    getTitle () {
      return this.title
    },
    isWarning () {
      return this.warning && this.total > 0
    },
    getColumnClass () {
      return 'col-' + this.columnSize + ' col-md-' + this.columnMdSize
    }
  }
}
</script>
